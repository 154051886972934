import React from "react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import { AgGridReact } from "@ag-grid-community/react";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import { apiGetTeamHistory, apiGetAllAccounts } from "api/user";
import { TextField, MenuItem, Select, Tooltip, Grid, Button } from "@material-ui/core";
import { debounce } from "lodash";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import CsvDownloader from "react-csv-downloader";

const styles = () => ({
  teamHistoryContainer: {
    "& .ag-header-cell-resize": {
      position: "absolute",
      zIndex: 2,
      height: "100%",
      width: 8,
      top: 0,
      cursor: "ew-resize"
    },
    "& .ag-icon": {
      display: "inline"
    }
  },
  filterBy: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16
  },
  text: {
    fontSize: 16,
    marginRight: 8
  }
});

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) return -1;
    const dateAsString = moment(cellValue).format("DD/MM/YYYY");
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  inRangeInclusive: true
};

const textFilterParams = {
  filterOptions: ["contains", "notContains"],
  textFormatter: r => {
    if (r == null) return null;
    return r
      .toLowerCase()
      .replace(/[àáâãäå]/g, "a")
      .replace(/æ/g, "ae")
      .replace(/ç/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/ñ/g, "n")
      .replace(/[òóôõö]/g, "o")
      .replace(/œ/g, "oe")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");
  },
  debounceMs: 200,
  maxNumConditions: 1
};

const RenderAsin = ({ data: { asin, url } }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {asin}
    </a>
  );
};

const RenderInsight360Site = ({ data: { insight360Site } }) => {
  if (!insight360Site) return "N/A";
  return (
    <a href={insight360Site} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {insight360Site}
    </a>
  );
};

const RenderSourced = ({ data: { sourced } }) => {
  return moment(sourced).format("MMMM Do YYYY, h:mm A");
};

const RenderTooltip = ({ value }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <p>{value ? value : "N/A"}</p>
    </Tooltip>
  );
};

const RenderTitle = ({ value, data: { url } }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value ? value : "N/A"}
      </a>
    </Tooltip>
  );
};

const RenderImage = ({ value }) => {
  return <img style={{ height: 36 }} src={value} />;
};

const RenderSourcedFrom = ({ value }) => {
  return value === "MOBILE" ? "ScanEZ" : "AZInsight";
};

const listTime = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 days", value: "last30days" },
  { label: "All history", value: "all" }
];

const HISTORY_COLUMNS = {
  product_image: {
    id: "image",
    displayName: "Product Image"
  },
  product_title: {
    id: "title",
    displayName: "Product Title"
  },
  category: {
    id: "category",
    displayName: "Category"
  },
  bsr: {
    id: "bsr",
    displayName: "BSR"
  },
  asin: {
    id: "asin",
    displayName: "ASIN"
  },
  insight360Site: {
    id: "insight360Site",
    displayName: "Insight360 Site"
  },
  insight360Keyword: {
    id: "insight360Keyword",
    displayName: "Insight360 Keyword"
  },
  marketplace: {
    id: "marketplace",
    displayName: "Marketplace"
  },
  sourced: {
    id: "sourced",
    displayName: "Sourced"
  },
  source: {
    id: "source",
    displayName: "Sourced From"
  },
  email: {
    id: "email",
    displayName: "Account Email"
  }
};

class TeamHistory extends React.Component {
  limit = 100;

  constructor(props) {
    super(props);
    this.state = {
      time: "today",
      listAccount: [{ label: "All accounts", value: "all" }],
      account: "all",
      individual: false,
      totals: 0,
      filter: {}
    };
    this.columnDefs = this._defineColumns();
    this.gridOptions = Object.create({
      frameworkComponents: {
        RenderAsin,
        RenderSourced,
        RenderTooltip,
        RenderInsight360Site,
        RenderImage,
        RenderSourcedFrom,
        RenderTitle,
        btnExportCsv: this.BtnExportCsv
      },
      rowSelection: "multiple",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true // set filtering on for all cols
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: () => true,
      domLayout: "autoHeight",
      cacheBlockSize: this.limit,
      overlayNoRowsTemplate: "No results found"
    });
  }

  _defineColumns = () => {
    return [
      {
        headerName: HISTORY_COLUMNS.product_image.displayName,
        field: HISTORY_COLUMNS.product_image.id,
        width: 140,
        cellRenderer: "RenderImage"
      },
      {
        headerName: HISTORY_COLUMNS.product_title.displayName,
        field: HISTORY_COLUMNS.product_title.id,
        width: 300,
        cellRenderer: "RenderTitle",
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.category.displayName,
        field: HISTORY_COLUMNS.category.id,
        width: 200,
        cellRenderer: "RenderTooltip",
        valueFormatter: params => {
          if (!params.value) {
            return "N/A";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.bsr.displayName,
        field: HISTORY_COLUMNS.bsr.id,
        width: 100,
        valueFormatter: params => {
          if (!params.value) {
            return "N/A";
          }
        },
        filter: "agNumberColumnFilter"
      },
      {
        headerName: HISTORY_COLUMNS.asin.displayName,
        field: HISTORY_COLUMNS.asin.id,
        width: 130,
        cellRenderer: "RenderAsin",
        valueFormatter: params => {
          if (!params.value) {
            return "N/A";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.insight360Site.displayName,
        field: HISTORY_COLUMNS.insight360Site.id,
        width: 300,
        cellRenderer: "RenderInsight360Site",
        valueFormatter: params => {
          if (!params.value) {
            return "N/A";
          }
        },
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.insight360Keyword.displayName,
        field: HISTORY_COLUMNS.insight360Keyword.id,
        width: 200,
        valueFormatter: params => {
          if (!params.value) {
            return "N/A";
          }
        },
        cellRenderer: "RenderTooltip",
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.marketplace.displayName,
        field: HISTORY_COLUMNS.marketplace.id,
        width: 160,
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.sourced.displayName,
        field: HISTORY_COLUMNS.sourced.id,
        width: 200,
        filter: "agDateColumnFilter",
        filterParams,
        cellRenderer: "RenderSourced"
      },
      {
        headerName: HISTORY_COLUMNS.source.displayName,
        field: HISTORY_COLUMNS.source.id,
        width: 140,
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: HISTORY_COLUMNS.email.displayName,
        field: HISTORY_COLUMNS.email.id,
        width: 240,
        cellRenderer: "RenderTooltip",
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      }
    ];
  };

  componentDidMount() {
    apiGetAllAccounts().then(data => this.showListAccounts(data));
  }

  onBodyScroll = ({ direction, api, top }) => {
    if (direction === "vertical") {
      const viewPortHeight = api.gridPanel.bodyHeight;
      const dataHeight = api.gridPanel.eCenterViewport.clientHeight;
      if (top + viewPortHeight >= dataHeight - 50 && !this.state.hasMoreData) {
        this._loadCategory();
      }
    }
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridColumnApi = params.columnApi;
    this.onGetProducts();
  };

  onGetProducts = () => {
    const dataSource = this.getServerSideDatasource();
    this.gridApi.setServerSideDatasource(dataSource);
  };

  getServerSideDatasource = () => {
    return {
      getRows: debounce(params => {
        const { time, account, individual } = this.state;
        const { startRow, sortModel, filterModel } = params.request;
        const page = startRow === 0 || startRow === undefined ? 1 : startRow / this.limit + 1;
        apiGetTeamHistory({
          page,
          limit: this.limit,
          sortModel,
          filterModel,
          time,
          account,
          individual
        })
          .then(({ data, totals }) => {
            this.setState({ totals });
            if (totals === 0) {
              this.gridApi.showNoRowsOverlay();
            }
            params.successCallback(data, totals);
          })
          .catch(error => {
            console.error(error);
            params.failCallback();
          });
      }, 500)
    };
  };

  getRowNodeId(params) {
    return params._id;
  }

  onChangeTime = event => {
    this.setState({ time: event.target.value }, () => this.onGetProducts());
  };

  onChangeAccount = event => {
    this.setState({ account: event.target.value }, () => this.onGetProducts());
  };

  showListAccounts = allAccounts => {
    const list = [{ label: "All accounts", value: "all" }];
    // eslint-disable-next-line
    for (const account of allAccounts) {
      list.push({ label: account.email, value: account.email });
    }
    this.setState({ listAccount: list }, () => this.onGetProducts);
  };

  handleOnExportCsv = async () => {
    const { time, account, individual, totals, filter } = this.state;
    const page = 1;
    const { data } = await apiGetTeamHistory({
      page,
      limit: totals,
      filterModel: filter,
      time,
      account,
      individual
    });
    return data;
  };

  onFilterTextBoxChanged = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      individual: !!evt.target.value || evt.target.value !== ""
    });
    const filter = {
      title: {
        filterType: "text",
        type: "contains",
        filter: evt.target.value
      },
      category: {
        filterType: "text",
        type: "contains",
        filter: evt.target.value
      },
      email: {
        filterType: "text",
        type: "contains",
        filter: evt.target.value
      }
    };
    this.setState({ filter });
    this.gridApi.setFilterModel(filter);
  };

  render() {
    const { classes } = this.props;
    const { time, account, listAccount } = this.state;
    return (
      <div className={classes.teamHistoryContainer}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid key={0} item>
            <div className={classes.filterBy}>
              <div className={classes.text}>Filter by:</div>
              <div className={classes.text}>Time</div>
              <Select className={classes.text} onChange={this.onChangeTime} value={time}>
                {listTime.map(item => (
                  <MenuItem key={`item_time_select_${item.value}`} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.text}>and Account email</div>
              <Select className={classes.text} onChange={this.onChangeAccount} value={account}>
                {listAccount.map(item => (
                  <MenuItem key={`item_time_select_${item.value}`} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid key={1} item>
            <Grid container alignItems="center" spacing={2}>
              <Grid key="right-export" item>
                <Button
                  color="primary"
                  variant="contained"
                  href="/user/history-usage-statistics"
                  target="_blank"
                >
                  Usage Statistics
                </Button>
              </Grid>
              <Grid key="right-search" item>
                <TextField
                  id="filled-full-width"
                  label="Search"
                  style={{ margin: 8 }}
                  placeholder="Input to search category or product title or account email"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  onChange={this.onFilterTextBoxChanged}
                />
              </Grid>
              <Grid key="right-export" item>
                <CsvDownloader
                  className="MuiButton-outlinedSizeSmall"
                  datas={this.handleOnExportCsv()}
                  columns={Object.values(HISTORY_COLUMNS)}
                  separator=";"
                  filename="history.csv"
                  text="Export CSV"
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
          <AgGridReact
            style={{ width: "100%", height: "100%" }}
            animateRows
            enableRangeSelection
            getRowNodeId={this.getRowNodeId}
            gridOptions={this.gridOptions}
            onGridReady={this.onGridReady}
            sideBar="columns"
            localeText={{ columns: "Configure Columns" }}
            pagination
            paginationPageSize={this.limit}
            modules={[ServerSideRowModelModule]}
            rowModelType="serverSide"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TeamHistory);
