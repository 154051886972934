import React from "react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

import { AgGridReact } from "@ag-grid-community/react";
import moment from "moment";
import {
  apiGetHistoryUsageStatistics,
  apiGetSummaryHistoryUsageStatistics,
  apiGetAllAccounts
} from "api/user";
import {
  MenuItem,
  Select,
  Tooltip,
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TableSortLabel
} from "@material-ui/core";
import { debounce } from "lodash";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";

const textFilterParams = {
  filterOptions: ["contains", "notContains"],
  textFormatter: r => {
    if (r == null) return null;
    return r
      .toLowerCase()
      .replace(/[àáâãäå]/g, "a")
      .replace(/æ/g, "ae")
      .replace(/ç/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/ñ/g, "n")
      .replace(/[òóôõö]/g, "o")
      .replace(/œ/g, "oe")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");
  },
  debounceMs: 200,
  maxNumConditions: 1
};

const RenderAsin = ({ data: { asin, url } }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {asin}
    </a>
  );
};

const RenderInsight360Site = ({ data: { insight360Site } }) => {
  if (!insight360Site) return "N/A";
  return (
    <a href={insight360Site} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {insight360Site}
    </a>
  );
};

const RenderSourced = ({ data: { sourced } }) => {
  return moment(sourced).format("MMMM Do YYYY, h:mm A");
};

const RenderTooltip = ({ value }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <p>{value ? value : "N/A"}</p>
    </Tooltip>
  );
};

const RenderTitle = ({ value, data: { url } }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value ? value : "N/A"}
      </a>
    </Tooltip>
  );
};

const RenderImage = ({ value }) => {
  return <img style={{ height: 36 }} src={value} />;
};

const RenderSourcedFrom = ({ value }) => {
  return value === "MOBILE" ? "ScanEZ" : "AZInsight";
};

const listTime = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 days", value: "last30days" },
  { label: "All history", value: "all" }
];

const DETAIL_USAGE_STATISTICS_COLUMNS = {
  category: {
    id: "category",
    displayName: "Category"
  },
  marketplace: {
    id: "marketplace",
    displayName: "Marketplace"
  },
  percentage: {
    id: "percentage",
    displayName: "Percentage"
  }
};

const SUMMARY_USAGE_STATISTICS_COLUMNS = {
  name: {
    id: "name",
    displayName: "Account Name"
  },
  email: {
    id: "email",
    displayName: "Account Email"
  },
  total: {
    id: "asinsWatched",
    displayName: "ASINs searched"
  }
};

class UsageStatistics extends React.Component {
  limit = 100;

  constructor(props) {
    super(props);
    this.state = {
      time: "today",
      listAccount: [{ label: "All accounts", value: "all" }],
      account: "all",
      summaryUsageStatistics: [],
      order: "asc",
      orderBy: "email"
    };
    this.columnDefs = this._defineColumns();
    this.gridOptions = Object.create({
      frameworkComponents: {
        RenderAsin,
        RenderSourced,
        RenderTooltip,
        RenderInsight360Site,
        RenderImage,
        RenderSourcedFrom,
        RenderTitle,
        btnExportCsv: this.BtnExportCsv
      },
      rowSelection: "multiple",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true // set filtering on for all cols
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: () => true,
      domLayout: "autoHeight",
      cacheBlockSize: this.limit,
      overlayNoRowsTemplate: "No results found"
    });
  }

  _defineColumns = () => {
    return [
      {
        headerName: DETAIL_USAGE_STATISTICS_COLUMNS.category.displayName,
        field: DETAIL_USAGE_STATISTICS_COLUMNS.category.id,
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: DETAIL_USAGE_STATISTICS_COLUMNS.marketplace.displayName,
        field: DETAIL_USAGE_STATISTICS_COLUMNS.marketplace.id,
        width: 160,
        filter: "agTextColumnFilter",
        filterParams: textFilterParams
      },
      {
        headerName: DETAIL_USAGE_STATISTICS_COLUMNS.percentage.displayName,
        field: DETAIL_USAGE_STATISTICS_COLUMNS.percentage.id,
        width: 160,
        valueFormatter: params => Math.round(params.value * 100) / 100
      }
    ];
  };

  _defineSummaryColumns = () => {
    return [
      {
        headerName: SUMMARY_USAGE_STATISTICS_COLUMNS.name.displayName,
        field: SUMMARY_USAGE_STATISTICS_COLUMNS.name.id
      },
      {
        headerName: SUMMARY_USAGE_STATISTICS_COLUMNS.email.displayName,
        field: SUMMARY_USAGE_STATISTICS_COLUMNS.email.id
      },
      {
        headerName: SUMMARY_USAGE_STATISTICS_COLUMNS.total.displayName,
        field: SUMMARY_USAGE_STATISTICS_COLUMNS.total.id
      }
    ];
  };

  componentDidMount() {
    apiGetAllAccounts().then(data => {
      this.showListAccounts(data);

      const { orderBy } = this.state;
      this.getSummaryUsageStatisticAccounts(orderBy);
    });
  }

  onBodyScroll = ({ direction, api, top }) => {
    if (direction === "vertical") {
      const viewPortHeight = api.gridPanel.bodyHeight;
      const dataHeight = api.gridPanel.eCenterViewport.clientHeight;
      if (top + viewPortHeight >= dataHeight - 50 && !this.state.hasMoreData) {
        this._loadCategory();
      }
    }
  };

  onDetailUsageStatisticsGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.onGetDetailUsageStatistics();
  };

  onGetDetailUsageStatistics = () => {
    const dataSource = this.getServerSideDatasource();
    this.gridApi.setServerSideDatasource(dataSource);
  };

  getServerSideDatasource = () => {
    return {
      getRows: debounce(params => {
        const { time, account } = this.state;
        const { startRow, sortModel, filterModel } = params.request;
        const page = startRow === 0 || startRow === undefined ? 1 : startRow / this.limit + 1;
        apiGetHistoryUsageStatistics({
          page,
          limit: this.limit,
          sortModel,
          filterModel,
          time,
          account
        })
          .then(resp => {
            const resultTotal = resp?.length || 0;
            if (resultTotal === 0) {
              this.gridApi.showNoRowsOverlay();
            }
            params.successCallback(resp || [], resultTotal);
          })
          .catch(error => {
            console.error(error);
            params.failCallback();
          });
      }, 500)
    };
  };

  getSummaryUsageStatisticAccounts(fieldOrder) {
    const { orderBy, order, time, account, listAccount } = this.state;
    const sort = orderBy === fieldOrder && order === "asc" ? "desc" : "asc";

    this.setState({
      order: sort,
      orderBy: fieldOrder || orderBy
    });
    apiGetSummaryHistoryUsageStatistics({
      sortModel: [
        {
          colId: fieldOrder || orderBy,
          sort
        }
      ],
      time,
      account
    })
      .then(resp => {
        const accountSummaryUsageStatistics = resp
          .map(item => {
            const account = listAccount.find(acc => {
              return item.value === acc.email;
            });
            return account ? Object.assign({}, account, item) : {};
          })
          .filter(item => Object.values(item).length !== 0);

        if (account !== "all") {
          this.setState({
            summaryUsageStatistics: accountSummaryUsageStatistics.filter(
              item => item.email === account
            )
          });
        } else {
          this.setState({ summaryUsageStatistics: accountSummaryUsageStatistics });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getRowNodeId(params) {
    return params._id;
  }

  onChangeTime = event => {
    this.setState({ time: event.target.value }, () => {
      this.onGetDetailUsageStatistics();
      this.getSummaryUsageStatisticAccounts();
    });
  };

  onChangeAccount = event => {
    this.setState({ account: event.target.value }, () => {
      this.onGetDetailUsageStatistics();
      this.getSummaryUsageStatisticAccounts();
    });
  };

  showListAccounts = allAccounts => {
    const list = [{ label: "All accounts", value: "all" }];
    // eslint-disable-next-line
    for (const account of allAccounts) {
      list.push({ label: account.email, value: account.email });
    }
    this.setState({ listAccount: list }, () => this.onGetDetailUsageStatistics);
  };

  handleSortSummaryUsageStatistics = fieldId => {
    this.getSummaryUsageStatisticAccounts(fieldId);
  };

  render() {
    const { time, account, listAccount, order, orderBy, summaryUsageStatistics } = this.state;

    return (
      <div>
        <div>Filter by:</div>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>Time</Grid>
          <Grid item>
            <Select onChange={this.onChangeTime} value={time}>
              {listTime.map(item => (
                <MenuItem key={`item_time_select_${item.value}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>and Account email</Grid>
          <Grid item>
            <Select onChange={this.onChangeAccount} value={account}>
              {listAccount.map(item => (
                <MenuItem key={`item_time_select_${item.value}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2} style={{ marginTop: 10 }}>
          <Grid key="summary-usage-statistics" item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    {this._defineSummaryColumns().map(col => (
                      <TableCell
                        key={col.field}
                        sortDirection={orderBy === col.field ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === col.field}
                          direction={orderBy === col.field ? order : "asc"}
                          onClick={() => this.handleSortSummaryUsageStatistics(col.field)}
                        >
                          {col.headerName}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summaryUsageStatistics.map((row, key) => (
                    <TableRow
                      key={`summary-email-${key}`}
                      selected={account === row.email && "true"}
                    >
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell align="right">{row.asinsWatched}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid key="detail-usage-statistics" item xs={12} md={6}>
            <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
              <AgGridReact
                style={{ width: "100%", height: "100%" }}
                animateRows
                enableRangeSelection
                getRowNodeId={this.getRowNodeId}
                gridOptions={this.gridOptions}
                onGridReady={this.onDetailUsageStatisticsGridReady}
                pagination
                paginationPageSize={this.limit}
                modules={[ServerSideRowModelModule]}
                rowModelType="serverSide"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default UsageStatistics;
